import React from "react"

const CalloutWithImage = ({ pageInfo }) => {
	let link;

	if ( pageInfo.linkUrl ) {
		link = 	( <div>
				<form action={ pageInfo.linkUrl }>
					<input type="submit" className="btn btneffect" value={ pageInfo.linkText } />
				</form>
			</div>
		)
	} else {
		link = '';
	}


	return (
		<section className={'container callout-with-image'}>
			<div className={"row img-" + pageInfo.imgPosition }>
				<div className="image">
					<img
						src={ pageInfo.imageSrc }
						alt=""
					/>
				</div>
				<div className="contentBlock">
					<h2 className="callout__title">{ pageInfo.title }</h2>
					<p className="callout__content">{ pageInfo.content }</p>
					{ link }

				</div>
			</div>
		</section>
	)
}

export default CalloutWithImage
