import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

const HeroImage = ({ pageInfo }) => {

	return (
		<section className={'hero-image'}>
			<Container>
				<Row>
					<Col>
						<h2>{pageInfo.title}</h2>
						<img className="img-fluid" src={pageInfo.imageSrc} />
						<p>{pageInfo.content}</p>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

export default HeroImage
