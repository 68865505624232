import React from "react"
import { Carousel, CarouselItem, Row, Col, Container } from "react-bootstrap"

const PageCarousel = ({ pageInfo }) => {
	let i=0;
	let pages = pageInfo.pages.map((page) => {
            i++;
            return (
				<Carousel.Item key={i}>
					<div className="background" style={{backgroundImage: `url(${page.imageSrc})`}}></div>
					<Carousel.Caption>
						<h3>{page.title}</h3>
						<p>{page.content}</p>
					</Carousel.Caption>
				</Carousel.Item>
			)
        }

	)

	let noTopPadding = '';
	let headingContent = '';

	if( pageInfo.title.length != 0 || pageInfo.content.length != 0 ){
		noTopPadding = 'no-top-padding';
		headingContent = <div className="bg-secondary p-4 text-white text-center">
			<Container>
				<Row>
					<Col>
						<div className="h2">{pageInfo.title}</div>
						<div>{pageInfo.content}</div>
					</Col>
				</Row>
			</Container>
		</div>
	}

	return (
		<section className={noTopPadding}>
			{headingContent}
			<Carousel interval="8000" wrap="true" touch>
				{pages}
			</Carousel>
		</section>

	)
}

export default PageCarousel
