import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const Awards = ({ children, pageInfo }) => {

	return (

		<section className={'awards'}>
			<Container>
				<Row>
					<Col>
						<h2>{pageInfo.title}</h2>
						<p>{pageInfo.content}</p>
					</Col>
				</Row>
				{children}
			</Container>
		</section>
	)
}

export default Awards

