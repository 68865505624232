import React from "react"

const Content = ({ children, pageInfo }) => {
	let header;

	if (pageInfo.title) {
		header = (
			<div className="row">
				<div className="col text-center p-4">
					<h2>
						<strong>{pageInfo.title}</strong>
					</h2>
				</div>
			</div>
		)

	}

	return (
		<section className={'container content'}>
			{header}
			<div className="row">
				{children}
			</div>
		</section>
	)
}

export default Content
