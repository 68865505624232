import React from "react"

const Introduction = ({ pageInfo }) => {
	return (
		<section className="introduction" id="goto_Security_Intro">
			<div className="container">
				<div className="row">
					<div className="col">
						<h3 className={'title'} >
							{ pageInfo.title }
						</h3>
						<div className={'content'}>
							{ pageInfo.content }
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Introduction