import React from "react"

const Solutions = ({ pageInfo }) => {

	return (
		<section className={'container solutions'}>
			<div className="row">
				<h1 id="solutions_title">Solutions for Enterprise Protection</h1>
			</div>
			<div className="row no-gutters">
				<div className="col-12 col-lg-4">
					<div>
						<img alt="" className="d-inline-block align-top solutions_img" src={ pageInfo.imgUrl_1 } />
						<h3 className="solutions_title"><strong>Small Business</strong></h3>
						<p className="solutions_text">Small organizations can quickly secure any environment with our targeted, standard design architectures.</p>
						<p className="solutions_item"><strong>2 to 100 endpoints</strong></p>
						<p className="solutions_item"><strong>1 to 2 physical locations</strong></p>
					</div>
				</div>

				<div className="col-12 col-lg-4">
					<div>
						<img alt="" className="d-inline-block align-top solutions_img" src={ pageInfo.imgUrl_2 } />
						<h3 className="solutions_title"><strong>Medium Enterprise</strong></h3>
						<p className="solutions_text">Enterprise-class cybersecurity is a snap with QNS solutions for Medium sized companies.</p>
						<p className="solutions_item"><strong>100 to 4,999 endpoints</strong></p>
						<p className="solutions_item"><strong>2 to 10 physical locations</strong></p>
					</div>
				</div>

				<div className="col-12 col-lg-4">
					<div>
						<img alt="" className="d-inline-block align-top solutions_img" src={ pageInfo.imgUrl_3 } />
						<h3 className="solutions_title"><strong>Large Enterprise</strong></h3>
						<p className="solutions_text">Reduce the complexity and daily maintenance burden of your enterprise security solution with QNS.</p>
						<p className="solutions_item"><strong>unlimited</strong></p>
						<p className="solutions_item"><strong>unlimited</strong></p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Solutions

