import React from "react"
import { Container, Row, Col, Card, CardDeck } from "react-bootstrap"

const MultiColumn = ({ pageInfo }) => {

	let count = 1;
	let cards = pageInfo.columns.map((card) =>
		<Col key={card.key} xs="12" sm="6" xl="4" >
			<Card id={card.key} data-count={count++}>
				<Card.Body>
					<Card.Img src={card.image} />
					<Card.Title>{card.title}</Card.Title>
					<Card.Text>
						<small>
							{card.content}
						</small>
					</Card.Text>
				</Card.Body>
			</Card>
		</Col>
	)



	return (
		<section className={'multi-column'}>
			<Container>
				<Row>
					{cards}
				</Row>
			</Container>
		</section>
	)

}

export default MultiColumn
