import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"

import imgBoxes from '../images/icons/IconBoxes.svg';
import imgChip from '../images/icons/IconChip.svg';
import imgPeople from '../images/icons/IconPeople.svg';
import imgMetaAce from '../images/icons/MetaACE.svg';

import Layout from "../components/layout"
import JumbotronHome from "../components/jumbotron-home"
import Introduction from "../components/introduction"
import ContentHeader from "../components/content-header"
import SEO from "../components/seo"
import MultiColumn from "../components/multi-column"
import HeroImage from "../components/hero-image"
import HeroVideo from "../components/hero-video"
import Solutions from "../components/solutions";
import Awards from "../components/awards";
import CalloutWithImage from "../components/callout-with-image"
import FiftyFifty from "../components/fifty-fifty"
import Content from "../components/content"
import Jumbotron from "../components/jumbotron"
import ParallaxBox from "../components/parallax-box"
import CalloutRepeater from "../components/callout-repeater"
import Carousel from "../components/carousel"
import Modal from "../components/modal"

const IndexPage = () => (
	<>
		<Layout pageInfo={{ pageName: "index" }}>

			<div className="overlay"></div>

			<SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`]} />

			<JumbotronHome pageInfo={{ title: "BlendICS", body: "Subtitle" }} />

			<Introduction pageInfo={{title: "Blendics Tools and Solutions Make it Possible to Ensure Synchronizer Reliability", content: "" }} />

			<MultiColumn
				pageInfo={{columns: [
						{key: 'col-1', image: imgChip, title: 'About Blendics', content: 'It was 2004, and three of our founders were attending an international symposium they organized on Clockless Computing (Coordinating Billions of Transistors), at Washington University in St. Louis, Missouri.  In the program, leaders in asynchronous computing reviewed future design challenges imposed on IC densities according to Moore’s Law.'},
						{key: 'col-2', image: imgPeople, title: 'Innovation', content: 'Blended Integrated Circuit Systems (Blendics) is an approach that utilizes both traditional and new design methods to enable the continued evolution of the integrated circuit.  With Blendics’ solutions, your organization can continue to leverage your expertise and investment in existing IP-Cores and standard development tools as you design and develop the Integrated Circuits (ICs) of…'},
						{key: 'col-3', image: imgBoxes, title: 'Solutions', content: 'Blendics has the first commercially available tool-set to allow IC designers to simulate synchronizer behavior and to identify issues before fabrication.  Additionally, we have a myriad of professional services to support organizations through technical challenges inhibiting their ability to innovate and grow.'}
					] }}
			/>


            <FiftyFifty pageInfo={{ background: 'black', color: 'white', imgUrl: imgMetaAce }} >

                <Container>
                    <Row>
                        <Col xs="12" md="8">
                            <h4>Free Software | Download Now!</h4>
                            <p>MetaACE LTD is a free, node-limited version of the MetaACE metastability analysis tool. MetaACE LTD provides all of the features of MetaACE except that it is limited to evaluating circuits with less than 250 nodes. Experience shows that many synchronizers can be evaluated quite well by including only bulk capacitance in the extracted netlists. Meta ACE LTD allows many synchronizers to be evaluated with results typically within 5-10% of true behavior. Users can use MetaACE LTD to evaluate their designs, quickly, and use the same configurationfiles in the professional version of MetaACE for highly accurate simulations of fully extracted netlists.</p>
                        </Col>
                        <Col xs="12" md="4">
                            <img src={imgMetaAce} />
                            <br />
                            <br />
                            <Button variant="success">Download</Button>{' '}
                        </Col>
                    </Row>
                </Container>

            </FiftyFifty>

		</Layout>
	</>
)

export default IndexPage
