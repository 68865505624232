import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

const CalloutRepeater = ({ pageInfo }) => {

	let i = 0;
	let callouts = pageInfo.callouts.map((callout) =>
		<div key={i++} className={'card mb-3 text-' + callout.textStyle + ' bg-' + callout.bgStyle }>

			<div className="card-header row no-gutters">
				<Col>
					<div className="d-none d-sm-block">
						<div className="card-title">
							{ callout.title }
						</div>
					</div>
					<a className="d-inline d-sm-none" data-toggle="collapse" href={'#callout-' + callout.id} role="button">
						<div className="card-title">
							{ callout.title }
						</div>
						<i id={'callout-' + callout.id + '-plus'} className="card-expander fa-fw fal fa-plus-square d-inline"></i>
						<i id={'callout-' + callout.id + '-minus'} className="card-expander fa-fw fal fa-minus-square d-none"></i>
					</a>
				</Col>
			</div>
			<div className="collapse" id={'callout-' + callout.id}>
				<div className="card-body">
					<p className="card-text "><small>{ callout.content }</small></p>
				</div>
			</div>
		</div>
	)


	return (
		<section className={'callout-repeater'}>
			<Container>
				<Row>
					<Col>
						<div className="callout-repeater-title">
							<h2>{ pageInfo.title }</h2>
							<p>{ pageInfo.content }</p>
						</div>
					</Col>
				</Row>
				<div className="card-columns">
					{callouts}
				</div>
			</Container>
		</section>
	)
}

export default CalloutRepeater
