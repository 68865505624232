import React from "react"
import { Parallax, Background } from 'react-parallax';

const ParallaxBox = ({ children, pageInfo }) => {
	return (
		<section className={'parallax-box'}>
			<Parallax
				blur={0}
				bgImage={pageInfo.imgSrc}
				bgImageAlt=""
				strength={300}
			>
				<div style={{ height: pageInfo.height }}>
					<div className="parallax-box-child-content">
						{children}
					</div>
				</div>
			</Parallax>
		</section>
	)
}

export default ParallaxBox
