import React from "react"

const ContentHeader = ({ pageInfo }) => {
	return (
		<section className={'container content-header'}>
			<div className="row">
				<div className="col text-center p-4">
					<h2>
						<strong>{pageInfo.content}</strong>
					</h2>
				</div>
			</div>
		</section>
	)
}

export default ContentHeader
